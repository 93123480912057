import * as React from "react"
import {navigate} from "gatsby";

const Footer = ({ siteTitle }) => (
    <footer>
        <div className={"content"}>
            <h2>Impressum</h2>
            <address>
                Wyssmann Treuhand GmbH
                <br/>
                Süristrasse 50 / Bramberg
                <br/>
                3176 Neuenegg
            </address>
            <a onClick={() => navigate("/impressum")} href="/impressum">Impressum</a><br/>
            <a onClick={() => navigate("/datenschutz")} href="/datenschutz">Datenschutzerklärung</a>
            <p>
                Gestaltung und Programmierung:
                <br/>
                Captns ● Konzept & Gestaltung
                <br/>
                <a href={"https://captns.ch"} target={"_blank"} rel={"noreferrer"}>www.captns.ch</a>
            </p>
        </div>
    </footer>
)

export default Footer
