import * as React from "react"
import Seo from "../components/common/seo"
import Header from "../components/sections/header"
import Footer from "../components/sections/footer"

import "../styles/main.scss"
import { useStaticQuery, graphql } from "gatsby";

const ImpressumPage = () => (
    <>
        <Seo title="Wyssmann Treuhand - Impressum" />
        <Header />
        <Content ></Content>
        <Footer />
    </>
)

 function   Content() {
     const data = useStaticQuery (
         graphql`
            query {
                allMarkdownRemark( filter: { frontmatter: {title: {eq: "Impressum"}}}) {
                    edges {
                        node {
                            id
                            html
                            frontmatter {
                                title
                            }
                        }
                    }
                },
            }
        `
     )

    return (
        <section>
            {data.allMarkdownRemark.edges.map(({node}) => (
                <div key={node.id} id={node.id} className="content">
                    <h2>{node.frontmatter.title}</h2>
                    <div dangerouslySetInnerHTML={{ __html: node.html }} />
                </div>
            ))}
        </section>
    )
}





export default ImpressumPage
